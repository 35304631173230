import React, { PureComponent } from 'react';
import {
  SEO,
  LevelHero,
  Inspiration,
  LevelChallenge,
  LevelBenefits,
  LevelObjective
} from 'components';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import level10Icon from 'images/Shared/LevelIcons/WhiteStroke/level_10_icon.svg';

class Level10 extends PureComponent {
  state = {
    componentHasMounted: false
  };

  componentDidMount() {
    this.setState({
      componentHasMounted: true
    });
  }

  render() {
    const { data, viewportWidth, user, game, selectedLevel } = this.props;
    const { levelData, spaceship } = data;
    const {
      benefits,
      challenge,
      duration,
      levelPageSummary,
      levelVideoCode,
      objective,
      scoring,
      title,
      subTitle
    } = levelData.childMarkdownRemark.frontmatter;
    let { tools } = levelData.childMarkdownRemark.frontmatter;
    const { componentHasMounted } = this.state;
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if (uniqueDocs.level === selectedLevel) {
          tools = {
            list: tools.list,
            link: uniqueDocs.link
          };
        }
      });
    }
    return user ? (
      <Wrapper>
        <SEO pageSpecificTitle="Level 10" pageSpecificDescription={levelPageSummary} />
        <LevelHero
          level={10}
          title={title}
          subTitle={subTitle}
          levelPageSummary={levelPageSummary}
          levelIcon={level10Icon}
          levelVideoCode={levelVideoCode}
        />
        <LevelObjective objective={objective} />
        <LevelChallenge challenge={challenge} duration={duration} tools={tools} />
        <Overview>
          <p
            style={{
              fontSize: '1.5rem',
              lineHeight: '1.333em',
              maxWidth: '926px',
              margin: '0 auto 3.5rem'
            }}>
            The market conditions are constantly changing and the competitors lie in wait, we must
            assume they will put up a fierce resistance.
            <br />
            Our power lies in our team resolve, resourcefulness and readiness for the market
            reality.
            <br />
            <br />
            Here we prepare you one last time for the vagaries of the market, betting on your
            collective ingenuity to rise to each challenge as it comes your way.
            <br />
            <br />
            <b>
              There can only be 1 winner
              <br />
              Let the games begin!
            </b>
          </p>
        </Overview>
        <InnerWrapper>
          <LevelBenefits
            benefits={benefits}
            level={selectedLevel}
            objective={objective}
            viewportWidth={viewportWidth}
            scoring={scoring}
          />
        </InnerWrapper>
        <p
          style={{
            fontSize: '1.5rem',
            lineHeight: '1.333em',
            margin: '3.75em auto 1.4em',
            textAlign: 'center',
            maxWidth: '39.167em'
          }}>
          The bell has been rung, it’s the last round, the last lap, the final furlong, has the
          title got your name on it...we believe it may, but only time will tell ;)
        </p>
        <h3
          style={{
            fontSize: '2rem',
            margin: '0 auto 3.625em',
            textAlign: 'center',
            color: '#009245'
          }}>
          Sincerest Good luck to all players!
        </h3>
        <Inspiration
          img={spaceship}
          author="Victor Hugo"
          quote="All the armies of the world cannot stop an idea whose time has come"
          quoteLargeFontSize="4rem"
          maxWidth="39.625em"
          textWrapperTop="6em"
          viewportWidth={viewportWidth}
        />
      </Wrapper>
    ) : (
      componentHasMounted && <Redirect to="/" noThrow />
    );
  }
}

const Wrapper = styled.section``;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 9.1%;
`;

const Overview = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 0 9.027777777777777% 2.625rem;
  display: flex;
  flex-direction: column;

  > div {
    align-items: flex-start;
    display: inline-flex;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 2.75em;

    > p {
      margin-left: 0.833em;
      position: relative;
      top: 0.325em;
      font-weight: 400;
      line-height: 1.333em;
      max-width: 874px;

      > span {
        font-weight: 700;
      }

      > span:nth-of-type(2) {
        margin-top: 1em;
      }

      > span:not(:first-of-type) {
        display: block;
        color: var(--cx-dark-green);
      }

      > div {
        align-items: flex-start;
        display: inline-flex;
        width: 100%;

        p {
          margin-left: 0.833em;

          span {
            font-weight: 700;
          }
        }
      }
    }

    ul {
      margin-top: 0.75em;
      line-height: 1.333em;

      li {
        display: flex;
        margin-bottom: 0.75em;

        span {
          margin-right: 1.5em;
        }
      }
    }
  }
`;

export const Level10Query = graphql`
  {
    levelData: file(sourceInstanceName: { eq: "levelData" }, relativePath: { eq: "level_10.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          subTitle
          levelPageSummary
          levelVideoCode
          challenge {
            heading
            detail
          }
          objective
          benefits {
            forPlayers
            forTheCampaign
          }
          duration {
            time
            detail
          }
          scoring {
            challenge {
              points
              detail
            }
            teamGame {
              points
              detail
            }
          }
          tools {
            list
            link
          }
        }
      }
    }
    spaceship: file(relativePath: { eq: "Shared/Inspiration/spaceship.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 634, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Level10;
